import React from 'react';
export const Banner00DataSource = {
  wrapper: { className: 'banner0 kr6l02nqfx-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title kq7nrbyjdgq-editor_css',
    children: (
      <span>
        <p>Michael Isome</p>
      </span>
    ),
  },
  content: {
    className: 'banner0-content kq7nrvgmlp-editor_css',
    children: (
      <span>
        <p>Software Engineer</p>
      </span>
    ),
  },
  button: {
    className: 'banner0-button',
    children: (
      <span>
        <p>View Profile</p>
      </span>
    ),
    href: 'https://www.linkedin.com/in/michael-isome-796516107/',
    target: '_blank',
  },
};
export const Content10DataSource = {
  wrapper: {
    className: 'home-page-wrapper content1-wrapper kq7nva9zekm-editor_css',
  },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://storage.googleapis.com/michael-isome/acu-alma-matter-logo.png',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title kq7nusav5dc-editor_css',
    children: (
      <span>
        <p>Credentials</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content kq7nuyw004f-editor_css',
    children: (
      <span>
        <span>
          <p>Education: Bachelor's of Science in Computer Science</p>
          <p>Contact: (email) michaelisome@live.com; (phone: please ask via email)</p>
          <p>
            <br />
          </p>
          <p>Github: https://github.com/isome01</p>
        </span>
      </span>
    ),
  },
};
export const Feature50DataSource = {
  wrapper: {
    className: 'home-page-wrapper content7-wrapper kr6l609vvys-editor_css',
  },
  page: { className: 'home-page content7 kr6ktkn7ph9-editor_css' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Technologies and Frameworks</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
      },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <p>Web Development</p>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <h3>
                                  <p>UI Development</p>
                                  <p>
                                    <br />
                                  </p>
                                  <p>
                                    AEM 6.4, jQuery, Node, Express, Angular4,
                                    ReactJS, Redux, Hooks, SDL2
                                  </p>
                                  <p>
                                    <br />
                                  </p>
                                  <p>Backend Development</p>
                                  <p>
                                    Node.js, Python Flask (WSGI), PHP (Symfony)
                                  </p>
                                  <p>
                                    <br />
                                  </p>
                                  <p>Databases</p>
                                  <p>
                                    MySQL, Microsoft SQL Server, MongoDB,
                                    PostgreSQL, GraphQL
                                  </p>
                                </h3>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              'https://storage.googleapis.com/michael-isome/full-web-stack-logo.png',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'elem~kr6jmi3cgq',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <span>
                  <p>Cloud Development</p>
                </span>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <span>
                                                <span>
                                                  <h3>
                                                    <p>AWS Cloud Stack</p>
                                                    <p>
                                                      &nbsp;- Competencies: EC2,
                                                      RDS, Lambda, SES, SNS, S3
                                                    </p>
                                                    <p>
                                                      <br />
                                                    </p>
                                                    <p>DOMO</p>
                                                    <p>
                                                      <br />
                                                    </p>
                                                    <p>
                                                      &nbsp;- Developer
                                                      Environment; Data
                                                      Connector (JS- ECMA5)
                                                      development&nbsp;
                                                    </p>
                                                    <p>
                                                      <br />
                                                    </p>
                                                    <p>Google:</p>
                                                    <p>
                                                      &nbsp;- Firebase
                                                      (Backend); Gmail, Geocode,
                                                      Google Maps, and Google
                                                      Storage APIs
                                                    </p>
                                                  </h3>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              'https://storage.googleapis.com/michael-isome/cloud-stack-logo.PNG',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'elem~kr6jmmlwi2j',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>Other technologies</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <span>
                                              <h3>
                                                <p>Stack Development: </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  LAMP Stack - Linux (Ubuntu
                                                  18.04, Amazon Linux), Apache,
                                                  MySQL and PHP
                                                </p>
                                                <p>
                                                  <span>
                                                    MERN Stack - Mongo, Express,
                                                    Node and ReactJS
                                                  </span>
                                                  <br />
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  Relevant (API) Platform
                                                  Development:{' '}
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p> - Windows API (C++)</p>
                                                <p>
                                                  {' '}
                                                  - SDL API (C, C++, PowerShell
                                                  modules)
                                                </p>
                                              </h3>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
            md: 10,
            xs: 24,
          },
        },
      },
    ],
  },
};
export const Content90DataSource = {
  wrapper: {
    className: 'home-page-wrapper content9-wrapper kq7o1gj2khk-editor_css',
  },
  page: { className: 'home-page content9' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'image', children: '', className: 'title-image' },
      {
        name: 'title',
        children: (
          <span>
            <p>Professional Experience</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'timeline',
    children: [
      {
        name: 'block0',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://storage.googleapis.com/michael-isome/public-data.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/qJnGrvjXPxdKETlVSrbe.svg',
          },
          name: {
            className: 'block-name kq7ny2bf4w9-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          <a href="https://www.publicdata.com" target="_blank">
                            Shadowsoft Inc.
                          </a>
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          post: {
            className: 'block-post',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
          time: {
            className: 'block-time kq7o0ategef-editor_css',
            children: (
              <span>
                <span>
                  <p>April (2017) - January (2018)</p>
                </span>
              </span>
            ),
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <p>Python Developer</p>
                <p>
                  · Maintained company legacy code for products implemented in
                  Perl.
                </p>
                <p>
                  · Responsibilities: implemented company web-automation
                  software using Python and Selenium. The relevance of the
                  content consisted of absconder/criminal information abroad the
                  United States.
                </p>
              </span>
            ),
          },
          content: { className: 'block-content', children: '' },
        },
      },
      {
        name: 'block1',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://storage.googleapis.com/michael-isome/infosys-logo.jpg',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          name: {
            className: 'block-name kq7o1yzn9j-editor_css',
            children: (
              <span>
                <span>
                  <p>
                    <a href="https://www.infosys.com" target="_blank">
                      Infosys Ltd.
                    </a>
                  </p>
                </span>
              </span>
            ),
          },
          post: {
            className: 'block-post',
            children: (
              <span>
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              </span>
            ),
          },
          time: {
            className: 'block-time kq7o41e13-editor_css',
            children: (
              <span>
                <p>April (2018) - April (2019)</p>
              </span>
            ),
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <span>
                  <p>Software Developer (Associate)</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    · Responsibilities: Resource utilization for inhouse product
                    (UI/Backend) and within multiple projects from client
                    Verizon Wireless (UI) by implementing new features and
                    maintenance of code, simultaneously.
                  </p>
                  <p>· Implemented up-to-date AEM 6.4 components in AEM.</p>
                  <p>· Utilized server-side rendering using ReactJS and AEM.</p>
                  <p>
                    · Implemented Verizon Support API, using AEM component
                    development and React to build and render the components;
                    maintained legacy UI.
                  </p>
                  <p>
                    <br />
                  </p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://www.clearc2.com/wp-content/uploads/2015/01/Social_Media_C2CRM_FB.jpg',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          name: {
            className: 'block-name kq7o578jscf-editor_css',
            children: (
              <span>
                <span>
                  <p>
                    <a href="https://www.clearc2.com" target="_blank">
                      ClearC2
                    </a>
                  </p>
                </span>
              </span>
            ),
          },
          post: {
            className: 'block-post',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
          time: {
            className: 'block-time kq7o6tu90id-editor_css',
            children: (
              <span>
                <p>April (2019) - May (2020)</p>
              </span>
            ),
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <span>
                  <p>Software Developer</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    · Project(s): (Inhouse) C2CRM and Verizon Wireless
                    (Several).
                  </p>
                  <p>
                    · Developed with (UI) React-Redux/Hooks and (Backend)
                    PHP/Symphony technologies with external API utilities.
                  </p>
                  <p>
                    Responsibilities: Resource utilization for inhouse product
                    (UI/Backend) and within multiple projects from client
                    Verizon (UI) by implementing new features and maintenance of
                    code, simultaneously.
                  </p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          textWrapper: { className: 'text-wrapper' },
          img: {
            className: 'block-img',
            children:
              'https://storage.googleapis.com/michael-isome/buffcitysoap-logo.png',
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/agOOBdKEIJlQhfeYhHJc.svg',
          },
          name: {
            className: 'block-name kq7o8sqzss-editor_css',
            children: (
              <span>
                <span>
                  <p>
                    <a href="https://www.buffcitysoap.com" target="_blank">
                      Buff City Soap (Corporate)
                    </a>
                  </p>
                </span>
              </span>
            ),
          },
          post: {
            className: 'block-post',
            children: (
              <span>
                <p>
                  <br />
                </p>
              </span>
            ),
          },
          time: {
            className: 'block-time kq7o7tz4yp-editor_css',
            children: (
              <span>
                <p>October (2020) -</p>
              </span>
            ),
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <span>
                  <p>Full Stack Developer</p>
                  <p>
                    <br />
                  </p>
                  <p>
                    • Implemented AWS Lambda middleware (python3.8) for order
                    routing and shipping
                  </p>
                  <p>
                    • Implemented and utilized PostgreSQL databases and
                    notification services (AWS SNS and HTML/CSS/JS) and
                    geocoding customer locations (Google API)
                  </p>
                  <p>
                    • Implemented and maintained REST API services for ship
                    logging, order routing solutions
                  </p>
                  <p>
                    • Integrated and spearheaded DOMO data connector
                    integrations (JavaScript/GraphQL)
                  </p>
                  <p>
                    • Integrated Python middleware for SaaS platforms such as
                    Marketman, ShipStation, Shopify
                  </p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'block-content',
            children: (
              <span>
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Projects</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            { name: 'image', className: 'content0-block-icon', children: '' },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        <a href="http://www.gemoutreach.org" target="_blank">
                          GEM Outreach
                        </a>
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    Spearheaded the Greg &amp; Earlene ministries outreach
                    project (ReactJS now PHP)
                    https://github.com/isome01/GEMinistries
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://storage.googleapis.com/michael-isome/github-logo.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>
                      <a
                        href="https://github.com/isome01/Xioudown"
                        target="_blank"
                      >
                        Xioudown 2D Game Engine
                      </a>
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>https://github.com/isome01/Xioudown</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            { name: 'image', className: 'content0-block-icon', children: '' },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: {
    className: 'home-page-wrapper footer0-wrapper kr6lg74i5vo-editor_css',
  },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        Michael Isome - Software Engineer<br />
      </span>
    ),
  },
};
